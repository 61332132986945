import React from 'react';

import './PageLoader.css';

const PageLoader = () => (
  <div id="preloader">
    <div id="loader"></div>
  </div>
);

export default PageLoader;
