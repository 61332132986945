import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

import Base from './components/Layout/Base';
import PageLoader from './components/Layout/PageLoader';

// Initialize google analytics page view tracking
// Source: https://levelup.gitconnected.com/using-google-analytics-with-react-3d98d709399b
const trackingId = 'UA-165581193-1';
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

const Index = lazy(() => import('./components/Pages/Index/Index'));
const Portfolio = lazy(() => import('./components/Pages/Portfolio/Portfolio'));
const NewsUpdates = lazy(() => import('./components/Pages/NewsUpdates/NewsUpdates'));
const Contact = lazy(() => import('./components/Pages/Contact/Contact'));

const App = () => {
  return (
    <Base>
      <BrowserRouter>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route exact path="/" component={Index} />
            <Route exact path="/portfolio" component={Portfolio} />
            <Route path="/news-updates" component={NewsUpdates} />
            <Route path="/contact" component={Contact} />
            <Redirect to="/" />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </Base>
  );
};

export default App;
