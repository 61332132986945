import React from 'react';

const year = new Date().getFullYear();

const Footer = () => (
  <footer className="footer pb-1 pb-lg-4 bg-light position-relative pt-7">
    <div className="container">
      <hr />
      <div className="row">
        <div className="col-md-6 text-md-left text-center">
          <p className="mb-0">S&#38;H Solutions Enterprise © {year}. All rights reserved.</p>
        </div>
        <div className="col-md-6 text-md-right text-center">
          <a href="https://www.facebook.com/SHSolutions.co" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-square fa-2x"></i>
          </a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
