import React from 'react';

import Footer from './Footer';

const Base = (props) => (
  <div>
    {props.children}
    <Footer />
  </div>
);

export default Base;
